import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "text-h6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_toggle = _resolveComponent("q-toggle")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_form = _resolveComponent("q-form")!
  const _component_FormLoading = _resolveComponent("FormLoading")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createBlock(_component_q_dialog, {
    modelValue: _ctx.show,
    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.show) = $event))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_card, { style: {"width":"90vw","max-width":"900px"} }, {
        default: _withCtx(() => [
          _createVNode(_component_q_card_section, null, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.row.id ? "Editar Aluno" : "Novo Aluno"), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_q_separator),
          _createVNode(_component_q_form, { onSubmit: _ctx.submit }, {
            default: _withCtx(() => [
              _createVNode(_component_q_card_section, { class: "row" }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_input, {
                    dense: "",
                    outlined: "",
                    "lazy-rules": "",
                    class: "col-7",
                    label: "Nome *",
                    modelValue: _ctx.form.nome,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.nome) = $event)),
                    rules: [_ctx.requiredField]
                  }, null, 8, ["modelValue", "rules"]),
                  _createVNode(_component_q_input, {
                    dense: "",
                    outlined: "",
                    class: "col-5",
                    label: "E-mail *",
                    modelValue: _ctx.form.email,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.email) = $event)),
                    rules: [_ctx.requiredField]
                  }, null, 8, ["modelValue", "rules"]),
                  _createVNode(_component_q_input, {
                    dense: "",
                    outlined: "",
                    "lazy-rules": "",
                    class: "col-4",
                    label: "Telefone",
                    mask: "(##) #####-####",
                    modelValue: _ctx.form.telefone,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.telefone) = $event))
                  }, null, 8, ["modelValue"]),
                  _createVNode(_component_q_input, {
                    dense: "",
                    outlined: "",
                    "lazy-rules": "",
                    mask: "##/##/####",
                    class: "col-4",
                    label: "Data Nascimento",
                    modelValue: _ctx.form.data_nascimento,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.data_nascimento) = $event))
                  }, null, 8, ["modelValue"]),
                  _createVNode(_component_q_select, {
                    dense: "",
                    outlined: "",
                    "lazy-rules": "",
                    "emit-value": "",
                    "map-options": "",
                    label: "Sexo",
                    class: "col-4",
                    "option-value": "id",
                    "option-label": "descricao",
                    modelValue: _ctx.form.genero_id,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.genero_id) = $event)),
                    options: _ctx.generos
                  }, null, 8, ["modelValue", "options"]),
                  _createVNode(_component_q_select, {
                    dense: "",
                    outlined: "",
                    "lazy-rules": "",
                    "emit-value": "",
                    "map-options": "",
                    multiple: "",
                    "use-chips": "",
                    "stack-label": "",
                    label: "Divisões *",
                    class: "col-6",
                    "option-value": "id",
                    "option-label": "descricao",
                    modelValue: _ctx.form.divisoes,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.form.divisoes) = $event)),
                    options: _ctx.divisoes
                  }, null, 8, ["modelValue", "options"]),
                  _createVNode(_component_q_select, {
                    dense: "",
                    outlined: "",
                    "lazy-rules": "",
                    "emit-value": "",
                    "map-options": "",
                    multiple: "",
                    "use-chips": "",
                    "stack-label": "",
                    label: "Turmas",
                    class: "col-6",
                    "option-value": "id",
                    "option-label": "nome",
                    modelValue: _ctx.form.turmas,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.form.turmas) = $event)),
                    options: _ctx.turmas
                  }, null, 8, ["modelValue", "options"]),
                  (_ctx.row.id)
                    ? (_openBlock(), _createBlock(_component_q_toggle, {
                        key: 0,
                        modelValue: _ctx.row.status,
                        "onUpdate:modelValue": [
                          _cache[7] || (_cache[7] = ($event: any) => ((_ctx.row.status) = $event)),
                          _ctx.status
                        ],
                        color: "green",
                        size: "lg",
                        "true-value": 1,
                        "false-value": 0,
                        label: _ctx.row.status ? 'Aluno ativo' : 'Aluno inativo'
                      }, null, 8, ["modelValue", "label", "onUpdate:modelValue"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createVNode(_component_q_separator),
              _createVNode(_component_q_card_actions, { align: "right" }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_btn, {
                    type: "submit",
                    color: "primary",
                    class: "q-ma-sm",
                    icon: "save",
                    label: "Salvar"
                  }),
                  (_ctx.row.id)
                    ? (_openBlock(), _createBlock(_component_q_btn, {
                        key: 0,
                        color: "orange",
                        class: "q-ma-sm",
                        icon: "lock",
                        label: "Resetar Senha",
                        onClick: _ctx.resetPassword
                      }, null, 8, ["onClick"]))
                    : _createCommentVNode("", true),
                  _withDirectives(_createVNode(_component_q_btn, {
                    class: "q-ma-sm",
                    icon: "close",
                    color: "red",
                    label: "Cancelar"
                  }, null, 512), [
                    [_directive_close_popup]
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["onSubmit"]),
          _createVNode(_component_FormLoading, { loading: _ctx.loading }, null, 8, ["loading"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}