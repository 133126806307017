
import { defineComponent, ref, watch } from "vue";
import AlunoColumn from "@/util/table-columns/AlunoColumn";
import AlunoForm from "@/components/admin/alunos/AlunoForm.vue";
import useList from "@/modules/useList";
import store from "@/store";
import copyToClipboard from 'quasar/src/utils/copy-to-clipboard.js';import exportFile from 'quasar/src/utils/export-file.js';;
import useQuasar from 'quasar/src/composables/use-quasar.js';;

export default defineComponent({
  components: { AlunoForm },
  setup() {
    const $q = useQuasar();
    const showActives = ref<boolean>(true);
    const {
      urlImg,
      rows,
      loading,
      filter,
      columns,
      list,
      formatData,
      modalForm,
      openModalForm,
    } = useList(
      store.getters.divisaoId
        ? "divisoes/alunos/" + store.getters.divisaoId + "/" + Number(showActives.value)
        : "alunos" + "/" + Number(showActives.value),
      AlunoColumn
    );

    const showNotify = (opts: any): void => {
      $q.notify(opts);
    };

    watch(showActives, async (newShowDisabled, oldShowDisabled) => {
      list(store.getters.divisaoId
        ? "divisoes/alunos/" + store.getters.divisaoId + "/" + Number(showActives.value)
        : "alunos" + "/" +  Number(showActives.value));
    });

    const wrapCsvValue = (val: any, formatFn?: any, row?: any): string => {
      let formatted = formatFn !== void 0
        ? formatFn(val, row)
        : val

      formatted = formatted === void 0 || formatted === null
        ? ''
        : String(formatted)

      formatted = formatted.split('"').join('""')
      /**
       * Excel accepts \n and \r in strings, but some other CSV parsers do not
       * Uncomment the next two lines to escape new lines
       */
      // .split('\n').join('\\n')
      // .split('\r').join('\\r')

      return `"${formatted}"`
    }

    const exportTable = (): void => {
        // naive encoding to csv format
        const content = [columns.value.map(col => wrapCsvValue(col.label))].concat(
          rows.value.map(row => columns.value.map(col => wrapCsvValue(
            typeof col.field === 'function'
              ? col.field(row)
              : row[ col.field === void 0 ? col.name : col.field ],
            null,
            row
          )).join(','))
        ).join('\r\n')

        const status = exportFile(
          'table-export.csv',
          content,
          'text/csv'
        )

        if (status !== true) {
          $q.notify({
            message: 'Browser denied file download...',
            color: 'negative',
            icon: 'warning'
          })
        }
      }

    const copyLink = (id: string): void => {
      copyToClipboard(id)
        .then(() => {
          showNotify({
            type: "positive",
            message: "Código copiado com sucesso",
          });
        })
        .catch(() => {
          showNotify({
            type: "positive",
            message: "Falha ao copiar Código do Aluno",
          });
        });
    };
    return {
      rows,
      loading,
      filter,
      columns,
      urlImg,
      list,
      modalForm,
      openModalForm,
      formatData,
      copyLink,
      showActives,
      exportTable,
    };
  },
});
