
import { ref, defineComponent, onMounted } from "vue";
import useQuasar from 'quasar/src/composables/use-quasar.js';;
import { requiredField } from "@/util/form-rules";
import FormLoading from "../../layout/FormLoading.vue";
import Genero from "@/interfaces/Genero";
import User from "@/interfaces/User";
import GeneroService from "@/services/admin/GeneroService";
import UserService from "@/services/admin/UserService";
import useForm from "@/modules/useForm";
import Divisao from "@/interfaces/Divisao";
import BaseService from "@/services/admin/BaseService";
import Turma from "@/interfaces/Turma";
import store from "@/store";
import DivisaoService from "@/services/auth/DivisaoService";
import Dialog from 'quasar/src/plugins/Dialog.js';;

interface Form {
  nome: string;
  email: string;
  telefone: string | null;
  data_nascimento: Date | null;
  biografia: string | null;
  genero_id: string;
  turmas: [];
  divisoes: [];
}

const baseForm: Form = {
  nome: "",
  email: "",
  telefone: "",
  data_nascimento: null,
  biografia: "",
  genero_id: "",
  turmas: [],
  divisoes: [],
};

export default defineComponent({
  components: { FormLoading },
  setup(props, { emit }) {
    const $q = useQuasar();
    const generos = ref<Genero[]>([]);
    const divisoes = ref<Divisao[]>([]);
    const turmas = ref<Turma[]>([]);
    const { show, form, row, loading, status, afterSubmit } = useForm(
      "alunos",
      "aluno",
      "o",
      baseForm,
      emit,
      "users"
    );

    const submit = (): void => {
      if (!row.value?.id) {
        loading.value = true;
        BaseService.store('alunos', form.value)
          .then(() => {
            afterSubmit();
            showNotify({
              type: "positive",
              message: `Aluno salvo com sucesso`,
            });
          })
          .catch((err) => {
            if (err.response.status == 409) {
              Dialog.create({
                title: err.response.data.message,
                message: `Deseja atualizar o aluno com as informações preenchidas?`,
                ok: {
                  color: "green",
                  label: "ATUALIZAR",
                },
                cancel: {
                  icon: "close",
                  color: "primary",
                  label: "CANCELAR",
                },
                persistent: true,
              }).onOk(() => {
                BaseService.store('alunos',  {...form.value, 'force': true})
                .then(() => {
                  afterSubmit();
                  showNotify({
                    type: "positive",
                    message: `Aluno salvo com sucesso`,
                  });
                });
              });
            }
            if (err.response.status == 422) {
              Object.entries(err.response.data.errors).forEach(([key, value]) => {
                const valueMessage: Array<string> = value as Array<string>;
                showNotify({
                  type: "negative",
                  message: valueMessage[0],
                });
              });
            } else {
              showNotify({
                type: "negative",
                message: err.response.data.message,
              });
            }
          })
          .finally(() => (loading.value = false));
      } else {
        loading.value = true;
        BaseService.update('alunos', row.value.id, form.value)
          .then(() => {
            afterSubmit();
            showNotify({
              type: "positive",
              message: `Aluno salvo com sucesso`,
            });
          })
          .catch((err) => {

            if (err.response.status == 409) {
              Dialog.create({
                title: err.response.data.message,
                message: `Deseja atualizar o aluno com as informações preenchidas?`,
                ok: {
                  color: "green",
                  label: "ATUALIZAR",
                },
                cancel: {
                  icon: "close",
                  color: "primary",
                  label: "CANCELAR",
                },
                persistent: true,
              }).onOk(() => {
                BaseService.store('alunos', {...form.value, 'force': true})
                .then(() => {
                  afterSubmit();
                  showNotify({
                    type: "positive",
                    message: `Aluno salvo com sucesso`,
                  });
                });
              });
            }
            if (err.response.status == 422) {
              Object.entries(err.response.data.errors).forEach(([key, value]) => {
                const valueMessage: Array<string> = value as Array<string>;
                showNotify({
                  type: "negative",
                  message: valueMessage[0],
                });
              });
            } else {
              showNotify({
                type: "negative",
                message: err.response.data.message,
              });
            }
          })
          .finally(() => (loading.value = false));
      }
    };

    const open = (data: User | null): void => {
      if (data) {
        row.value = JSON.parse(JSON.stringify(data));
        if (row.value?.id) {
          form.value = {
            nome: row.value.nome,
            email: row.value.email,
            telefone: row.value.telefone,
            data_nascimento: row.value.data_nascimento,
            biografia: row.value.biografia,
            genero_id: row.value.genero?.id ?? null,
            turmas: row.value.turmas.map((t: Turma) => t.id),
            divisoes: row.value.divisoes.map((d: Divisao) => d.id),
          };
        } else {
          form.value = JSON.parse(JSON.stringify(baseForm));
          form.value.divisoes = store.getters.divisaoId ? [store.getters.divisaoId] : [];
        }
      }
      show.value = true;
    };

    const resetPassword = (): void => {
      if (row.value) {
        loading.value = true;
        UserService.resetPassword(row.value.id)
          .then(() => {
            afterSubmit();
            showNotify({
              type: "positive",
              message: "Senha do usuário resetada com sucesso",
            });
          })
          .catch((err) => {
            console.error(err.response.data.message);
            showNotify({
              type: "negative",
              message: err.response.data.message,
            });
          })
          .finally(() => (loading.value = false));
      }
    };

    const getGeneros = (): void => {
      loading.value = true;
      GeneroService.list()
        .then((res) => {
          generos.value = res.data;
        })
        .catch((err) => {
          console.error(err.response.data.message);
          showNotify({
            type: "negative",
            message: err.response.data.message,
          });
        })
        .finally(() => (loading.value = false));
    };

    const getDivisoes = (): void => {
      loading.value = true;
      BaseService.list("divisoes")
        .then((res) => {
          divisoes.value = res.data;
        })
        .catch((err) => {
          console.error(err.response.data.message);
          showNotify({
            type: "negative",
            message: err.response.data.message,
          });
        })
        .finally(() => (loading.value = false));
    };

    const getTurmas = (): void => {
      loading.value = true;
      if (store.getters.divisaoId) {
        DivisaoService.getTurmas(store.getters.divisaoId)
          .then((res) => {
            turmas.value = res.data;
          })
          .catch((err) => {
            console.error(err.response.data.message);
            showNotify({
              type: "negative",
              message: err.response.data.message,
            });
          })
          .finally(() => (loading.value = false));
      } else {
        BaseService.list("turmas")
          .then((res) => {
            turmas.value = res.data;
          })
          .catch((err) => {
            console.error(err.response.data.message);
            showNotify({
              type: "negative",
              message: err.response.data.message,
            });
          })
          .finally(() => (loading.value = false));
      }
    };

    const showNotify = (opts: any): void => {
      $q.notify(opts);
    };

    onMounted(() => {
      getGeneros();
      getDivisoes();
      getTurmas();
    });

    return {
      show,
      open,
      form,
      row,
      requiredField,
      generos,
      loading,
      showNotify,
      getGeneros,
      submit,
      status,
      resetPassword,
      afterSubmit,
      divisoes,
      getDivisoes,
      turmas,
      getTurmas,
      store,
    };
  },
});
